export const images = [
  { src: "/Default_thumbnail.jpg" },
  {
    src: "/Thumbnail_0001.webp"
  },
  {
    src: "/Thumbnail_0002.webp"
  },
  {
    src: "/Thumbnail_0003.webp"
  },
  {
    src: "/Thumbnail_0004.webp"
  },
  {
    src: "/Thumbnail_0005.webp"
  },
  {
    src: "/Thumbnail_0006.webp"
  },
  {
    src: "/Thumbnail_0007.webp"
  },
  {
    src: "/Thumbnail_0008.webp"
  },
  {
    src: "/Thumbnail_0009.webp"
  },
  {
    src: "/Thumbnail_0010.webp"
  },
  {
    src: "/Thumbnail_0011.webp"
  },
  {
    src: "/Thumbnail_0012.webp"
  },
  {
    src: "/Thumbnail_0013.webp"
  },
  {
    src: "/Thumbnail_0014.webp"
  },
  {
    src: "/Thumbnail_0015.webp"
  },
  {
    src: "/Thumbnail_0016.webp"
  },
  {
    src: "/Thumbnail_0017.webp"
  },
  {
    src: "/Thumbnail_0018.webp"
  },
  {
    src: "/Thumbnail_0019.webp"
  },
  {
    src: "/Thumbnail_0020.webp"
  },
  {
    src: "/Thumbnail_0021.webp"
  },
  {
    src: "/Thumbnail_0022.webp"
  },
  {
    src: "/Thumbnail_0023.webp"
  },
  {
    src: "/Thumbnail_0024.webp"
  },
  { src: "/Thumbnail_0025.webp" },
  { src: "/Thumbnail_0026.webp" },
  { src: "/Thumbnail_0027.webp" },
  { src: "/Thumbnail_0028.webp" },
  { src: "/Thumbnail_0029.webp" },
  { src: "/Thumbnail_0030.webp" },
  { src: "/Thumbnail_0031.webp" },
  { src: "/Thumbnail_0032.webp" },
  { src: "/Thumbnail_0033.webp" },
  { src: "/Thumbnail_0034.webp" },
  { src: "/Thumbnail_0035.webp" },
  { src: "/Thumbnail_0036.webp" },
  { src: "/Thumbnail_0037.webp" },
  { src: "/Thumbnail_0038.webp" },
  { src: "/Thumbnail_0039.webp" },
  { src: "/Thumbnail_0040.webp" },
  { src: "/Thumbnail_0041.webp" },
  { src: "/Thumbnail_0042.webp" },
  { src: "/Thumbnail_0043.webp" },
  { src: "/Thumbnail_0044.webp" },
  { src: "/Thumbnail_0045.webp" },
  { src: "/Thumbnail_0046.webp" },
  { src: "/Thumbnail_0047.webp" },
  { src: "/Thumbnail_0048.webp" },
  { src: "/Thumbnail_0049.webp" },
  { src: "/Thumbnail_0050.webp" },
  { src: "/Thumbnail_0051.webp" },
  { src: "/Thumbnail_0052.webp" },
  { src: "/Thumbnail_0053.webp" },
  { src: "/Thumbnail_0054.webp" },
  { src: "/Thumbnail_0055.webp" },
  { src: "/Thumbnail_0056.webp" },
  { src: "/Thumbnail_0057.webp" },
  { src: "/Thumbnail_0058.webp" },
  { src: "/Thumbnail_0059.webp" },
  { src: "/Thumbnail_0060.webp" },
  { src: "/Thumbnail_0061.webp" },
  { src: "/Thumbnail_0062.webp" },
  { src: "/Thumbnail_0063.webp" },
  { src: "/Thumbnail_0064.webp" },
  { src: "/Thumbnail_0065.webp" },
  { src: "/Thumbnail_0066.webp" },
  { src: "/Thumbnail_0067.webp" },
  { src: "/Thumbnail_0068.webp" },
  { src: "/Thumbnail_0069.webp" },
  { src: "/Thumbnail_0070.webp" },
  { src: "/Thumbnail_0071.webp" },
  { src: "/Thumbnail_0072.webp" },
  { src: "/Thumbnail_0073.webp" },
  { src: "/Thumbnail_0074.webp" },
  { src: "/Thumbnail_0075.webp" },
  { src: "/Thumbnail_0076.webp" },
  { src: "/Thumbnail_0077.webp" },
  { src: "/Thumbnail_0078.webp" },
  { src: "/Thumbnail_0079.webp" },
  { src: "/Thumbnail_0080.webp" }
];
